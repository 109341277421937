<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.tesouraria == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                href="#"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                href="#"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/tesouraria')"
              >Modo tesouraria</a>              
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Renegociação</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0">
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Funções da 
                  <span>Plataforma</span>
                </h1>
                <img
                  :src="require('@/assets/images/form-effect-01.png')"
                  style="max-height: 35px"
                >
              </section>
              <!-- /fd-app-welcome -->
            </div>
          </div>
        </div>
        <section class="container mt-4">          
          <!-- Conteúdo principal -->
            <b-tabs>
              <!-- Itens -->
              <b-tab active @click="alteraFastNavegacao('exibirFuncoes')">
                <template #title>
                  Funções da plataforma
                </template>
                <div class="row">

                  <transition name="slide-fade">
                    <div v-if="fastNavegacao.exibirFuncoes" class="col-12">
                      <div class="card mb-4">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12 text-right">
                              <button
                                class="btn btn-sm btn-primary"
                                @click.prevent="alteraFastNavegacao('novaFuncao')"
                              >
                                <small>+ Nova função</small>
                              </button>
                            </div>
                            <div class="col-12 table-responsive mt-2">
                              <table class="table table-sm">
                                <thead class="thead-dark">
                                  <tr class="text-center">
                                    <th>
                                      <small
                                        class="font-weight-bold"
                                      >Função</small>
                                    </th>
                                    <th>
                                      <small
                                        class="font-weight-bold"
                                      >Pessoas</small>
                                    </th>
                                    <th>
                                      <small
                                        class="font-weight-bold"
                                      >Tipo</small>
                                    </th>
                                    <th class="text-center">
                                      <small class="font-weight-bold">Ações</small>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody v-if="fastFuncaoPessoa.loading">
                                  <tr>
                                    <td
                                      colspan="4"
                                      class="text-center"
                                    >
                                    Carregando...
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody v-else-if="fastFuncaoPessoa.total.length">
                                  <tr
                                    v-for="e in fastFuncaoPessoa.total"
                                    :key="e.id_funcao_pessoa"
                                  >
                                    <td class="align-middle text-center">
                                      {{ e.nome_funcao_pessoa }}
                                    </td>   
                                    <td class="align-middle text-center">
                                      {{ e.qtde_pessoas }}
                                    </td>  
                                    <td class="align-middle text-center">
                                      <div v-if="e.empregado">
                                        Empregado
                                      </div>
                                      <div v-if="e.vendedor">
                                        Vendedor
                                      </div>
                                      <div v-if="e.secretaria">
                                        Secretaria
                                      </div>
                                      <div v-if="e.parceiro">
                                        Parceiro
                                      </div>
                                      <div v-if="!e.empregado && !e.vendedor && !e.secretaria && !e.parceiro">
                                        Personalizado
                                      </div>
                                    </td>                 
                                    <td class="text-center align-middle">                                  
                                      <button
                                        class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                        @click="exibeModalEditarFuncao(e)"
                                      >
                                        <small>Editar</small>
                                      </button>
                                      <button
                                        v-if="!e.qtde_pessoas && !e.qtde_schemas"
                                        class="btn btn-sm btn-danger pt-0 pb-0 mr-2"
                                        @click="exibeModalExcluirFuncao(e)"
                                      >
                                        <small>Excluir</small>
                                      </button>
                                      <button
                                        v-else
                                        class="btn btn-sm btn-secondary pt-0 pb-0 mr-2"
                                        disabled
                                      >
                                        <small>Excluir</small>
                                      </button>

                                    </td>
                                  </tr>
                                </tbody>
                                <tbody v-else>
                                  <tr>
                                    <td
                                      colspan="4"
                                      class="text-center"
                                    >
                                      Nenhuma função cadastrada
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>              
                    </div>
                  </transition>

                  <transition name="slide-fade">
                    <div v-if="fastNavegacao.novaFuncao" class="col-12">
                      <div class="card mb-4">
                        <div class="card-header py-3">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                              <h6 class="m-0 font-weight-bold text-primary">
                                Nova função
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div class="card-body">
                          <div class="row">
                            <div class="col-12">
                              <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-5">
                                  <label>Nome da função</label>
                                  <input v-model="fastFuncaoPessoaNovo.nome_funcao_pessoa" :class="fastFuncaoPessoaNovo.nome_funcao_pessoa ? 'form-control' : 'form-control border border-danger'"/>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-3">
                                  <div class="fd-app-curso-aulas mt-0">
                                    <div class="fd-app-curso-aulas-list mt-0">
                                      <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                                        <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                                          <div class="col-12 text-center mb-1">
                                            <span>Empregado</span>
                                          </div>
                                          <div class="col-12 text-center">
                                            <label class="switch">
                                              <input   
                                                v-model="fastFuncaoPessoaNovo.empregado"                                        
                                                type="checkbox"                                           
                                              >
                                              <span class="slider round" />
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-3">
                                  <div class="fd-app-curso-aulas mt-0">
                                    <div class="fd-app-curso-aulas-list mt-0">
                                      <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                                        <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                                          <div class="col-12 text-center mb-1">
                                            <span>Vendedor</span>
                                          </div>
                                          <div class="col-12 text-center">
                                            <label class="switch">
                                              <input   
                                                v-model="fastFuncaoPessoaNovo.vendedor"                                        
                                                type="checkbox"                                           
                                              >
                                              <span class="slider round" />
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-3">
                                  <div class="fd-app-curso-aulas mt-0">
                                    <div class="fd-app-curso-aulas-list mt-0">
                                      <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                                        <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                                          <div class="col-12 text-center mb-1">
                                            <span>Secretaria</span>
                                          </div>
                                          <div class="col-12 text-center">
                                            <label class="switch">
                                              <input   
                                                v-model="fastFuncaoPessoaNovo.secretaria"                                        
                                                type="checkbox"                                           
                                              >
                                              <span class="slider round" />
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-3">
                                  <div class="fd-app-curso-aulas mt-0">
                                    <div class="fd-app-curso-aulas-list mt-0">
                                      <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                                        <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                                          <div class="col-12 text-center mb-1">
                                            <span>Parceiro</span>
                                          </div>
                                          <div class="col-12 text-center">
                                            <label class="switch">
                                              <input   
                                                v-model="fastFuncaoPessoaNovo.parceiro"                                        
                                                type="checkbox"                                           
                                              >
                                              <span class="slider round" />
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                                  <button v-if="!fastFuncaoPessoaNovo.loading" class="btn btn-sm btn-primary mr-2" @click="criaNovaFuncao()">
                                    Criar nova função
                                  </button>
                                  <button
                                    v-else
                                    class="btn btn-sm btn-secondary mr-2"
                                    disabled
                                  >
                                    <small>Criando...</small>
                                  </button>

                                  <button class="btn btn-sm btn-secondary mr-2" @click="alteraFastNavegacao('exibirFuncoes')">
                                    Voltar
                                  </button>
                                </div>
                              </div>  
                            </div>
                          </div>
                        </div>
                      </div>            
                    </div>
                  </transition>

                  <transition name="slide-fade">
                    <div v-if="fastNavegacao.editarFuncao" class="col-12">
                      <div class="card mb-4">
                        <div class="card-header py-3">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                              <h6 class="m-0 font-weight-bold text-primary">
                                Editar função
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div class="card-body">
                          <div class="row">
                            <div class="col-12">
                              <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-5">
                                  <label>Nome da função</label>
                                  <input v-model="fastFuncaoPessoaEditar.nome_funcao_pessoa" :class="fastFuncaoPessoaEditar.nome_funcao_pessoa ? 'form-control' : 'form-control border border-danger'"/>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-3">
                                  <div class="fd-app-curso-aulas mt-0">
                                    <div class="fd-app-curso-aulas-list mt-0">
                                      <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                                        <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                                          <div class="col-12 text-center mb-1">
                                            <span>Empregado</span>
                                          </div>
                                          <div class="col-12 text-center">
                                            <label class="switch">
                                              <input   
                                                v-model="fastFuncaoPessoaEditar.empregado"                                        
                                                type="checkbox"                                           
                                              >
                                              <span class="slider round" />
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-3">
                                  <div class="fd-app-curso-aulas mt-0">
                                    <div class="fd-app-curso-aulas-list mt-0">
                                      <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                                        <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                                          <div class="col-12 text-center mb-1">
                                            <span>Vendedor</span>
                                          </div>
                                          <div class="col-12 text-center">
                                            <label class="switch">
                                              <input   
                                                v-model="fastFuncaoPessoaEditar.vendedor"                                        
                                                type="checkbox"                                           
                                              >
                                              <span class="slider round" />
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-3">
                                  <div class="fd-app-curso-aulas mt-0">
                                    <div class="fd-app-curso-aulas-list mt-0">
                                      <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                                        <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                                          <div class="col-12 text-center mb-1">
                                            <span>Secretaria</span>
                                          </div>
                                          <div class="col-12 text-center">
                                            <label class="switch">
                                              <input   
                                                v-model="fastFuncaoPessoaEditar.secretaria"                                        
                                                type="checkbox"                                           
                                              >
                                              <span class="slider round" />
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-3">
                                  <div class="fd-app-curso-aulas mt-0">
                                    <div class="fd-app-curso-aulas-list mt-0">
                                      <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                                        <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                                          <div class="col-12 text-center mb-1">
                                            <span>Parceiro</span>
                                          </div>
                                          <div class="col-12 text-center">
                                            <label class="switch">
                                              <input   
                                                v-model="fastFuncaoPessoaEditar.parceiro"                                        
                                                type="checkbox"                                           
                                              >
                                              <span class="slider round" />
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                                  <button v-if="!fastFuncaoPessoaEditar.loading" class="btn btn-sm btn-primary mr-2" @click="editaFuncao()">
                                    Salvar alterações
                                  </button>
                                  <button
                                    v-else
                                    class="btn btn-sm btn-secondary mr-2"
                                    disabled
                                  >
                                    <small>Salvando...</small>
                                  </button>

                                  <button class="btn btn-sm btn-secondary mr-2" @click="alteraFastNavegacao('exibirFuncoes')">
                                    Voltar
                                  </button>
                                </div>
                              </div>  
                            </div>
                          </div>
                        </div>
                      </div>              
                    </div>
                  </transition>

                </div>
              </b-tab>
              <!-- /Itens -->
            </b-tabs>
            

          
          <!-- /Conteúdo principal -->
        </section>
        <div class="container">
          <div class="mt-5 mb-5">
            <a
              id="btn_voltar_modo_admin"
              class="btn btn-red-hollow"
              :href="'/plataforma/' + $route.params.id_plataforma + '/tesouraria'"
              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/tesouraria')"
            >
              <b-icon-arrow-return-left />
              Voltar
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalExcluirFuncao"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Excluir função?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirFuncao')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <p class="text-danger">A função {{fastFuncaoPessoaEditar.nome_funcao_pessoa}} será excluída permanentemente.</p>
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="excluiFuncao()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";

export default {
  name: "HomeInternoTesourariaFuncoes",
  components: {
    
  },
  mixins: [methods],
  data: function () {
    return {
      //#region variáveis gerais
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%", 
      fastNavegacao: {
        exibirFuncoes: true,
        novaFuncao: false,
        editarFuncao: false,
      },
      //#endregion
      //#region variáveis das funcoes
      fastFuncaoPessoa: {
        total: [],
        filtro: [],
        paginacao: [],
        loading: true
      },
      fastFuncaoPessoaNovo: {
        id_funcao_pessoa: 0,
        nome_funcao_pessoa: "",
        empregado: false,
        vendedor: false,
        secretaria: false,
        parceiro: false,
        id_plataforma: this.$route.params.id_plataforma,
        loading: false
      },
      fastFuncaoPessoaEditar: {
        id_funcao_pessoa: 0,
        nome_funcao_pessoa: "",
        empregado: false,
        vendedor: false,
        secretaria: false,
        parceiro: false,
        id_plataforma: this.$route.params.id_plataforma,
        loading: false
      },
      //#endregion

    };
  },
  mounted: function () {
    // Captura ID da plataforma pela URL
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'tesouraria')
        .then(() => {
          if (this.$store.state.fastPlataformaModulos.modulo_secretaria) {            
            this.$store.state.fastCarregando = false;
            this.getFuncaoPessoa(this.$route.params.id_plataforma)
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Módulo não disponível para sua plataforma", "error");
          }
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    async getFuncaoPessoa(id_plataforma){      
      this.fastFuncaoPessoa.loading = true
      this.promiseGetFastApi("api/fast_tesouraria_funcao_pessoa/lista", "id_plataforma=" + id_plataforma).then(res => {
        console.log("getFuncaoPessoa", res)
        if (res.length) {
          this.fastFuncaoPessoa.total = res
          this.fastFuncaoPessoa.filtro = res
        }
        else {
          this.fastFuncaoPessoa.total = []
          this.fastFuncaoPessoa.filtro = []
        }

        this.fastFuncaoPessoa.loading = false
      }).catch(e => {
        console.log(e)
        this.exibeToasty(e, "error")
        this.fastFuncaoPessoa.loading = false
      })
    },
    async criaNovaFuncao(){
      let erros = []
      this.fastFuncaoPessoaNovo.id_plataforma = this.$route.params.id_plataforma
      if (!this.fastFuncaoPessoaNovo.nome_funcao_pessoa) erros.push("O nome da função é obrigatória")
      
      if (erros.length) {
        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
        this.fastFuncaoPessoaNovo.loading = true
        this.promisePostFastApi(this.fastFuncaoPessoaNovo, "api/fast_tesouraria_funcao_pessoa/insere").then((res) => {
            console.log("criaNovaFuncao", res)
            if (res.length) {
              this.fastFuncaoPessoaNovo = {
                id_funcao_pessoa: 0,
                nome_funcao_pessoa: "",
                empregado: false,
                vendedor: false,
                secretaria: false,
                parceiro: false,
                id_plataforma: this.$route.params.id_plataforma,
                loading: false
              }
              this.fastFuncaoPessoa.total.push(res[0])
              this.alteraFastNavegacao('exibirFuncoes')
              this.exibeToasty("Função criada", "success")
            } else {
              this.exibeToasty("Erro ao criar função", "error")
              this.fastFuncaoPessoaNovo.loading = false
            }         
          }).catch((e) => {
            this.exibeToasty("Erro ao criar função", "error")
            this.fastFuncaoPessoaNovo.loading = false
          });
      }    
    },
    exibeModalEditarFuncao(e) {
      e.loading = false
      this.fastFuncaoPessoaEditar = e
      this.alteraFastNavegacao('editarFuncao')
    },
    async editaFuncao(){
      let erros = []
      this.fastFuncaoPessoaEditar.id_plataforma = this.$route.params.id_plataforma
      if (!this.fastFuncaoPessoaEditar.nome_funcao_pessoa) erros.push("O nome da função é obrigatória")
      
      if (erros.length) {
        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
        this.fastFuncaoPessoaEditar.loading = true
        this.promisePostFastApi(this.fastFuncaoPessoaEditar, "api/fast_tesouraria_funcao_pessoa/atualiza").then((res) => {
            console.log("editaFuncao", res)
            if (res.length) {
              this.fastFuncaoPessoaEditar = {
                id_funcao_pessoa: 0,
                nome_funcao_pessoa: "",
                empregado: false,
                vendedor: false,
                secretaria: false,
                parceiro: false,
                id_plataforma: this.$route.params.id_plataforma,
                loading: false
              }
              this.fastFuncaoPessoa.total.forEach(e => {
                if (e.id_funcao_pessoa == this.fastFuncaoPessoaEditar.id_funcao_pessoa) e = res[0]
              })
              this.alteraFastNavegacao('exibirFuncoes')
              this.exibeToasty("Alterações salvas", "success")
            } else {
              this.exibeToasty("Erro ao salvar alteração", "error")
              this.fastFuncaoPessoaEditar.loading = false
            }         
          }).catch((e) => {
            this.exibeToasty("Erro ao salvar alteração", "error")
            this.fastFuncaoPessoaEditar.loading = false
          });
      }    
    },
    exibeModalExcluirFuncao(e) {
      e.loading = false
      this.fastFuncaoPessoaEditar = e
      this.showModal('modalExcluirFuncao')
    },
    async excluiFuncao(){
      let erros = []
      this.fastFuncaoPessoaEditar.id_plataforma = this.$route.params.id_plataforma
      
      if (erros.length) {
        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
        this.fastFuncaoPessoaEditar.loading = true
        this.promisePostFastApi(this.fastFuncaoPessoaEditar, "api/fast_tesouraria_funcao_pessoa/exclui").then((res) => {
            console.log("excluiFuncao", res)
            this.getFuncaoPessoa(this.$route.params.id_plataforma)
            
            this.fastFuncaoPessoaEditar = {
              id_funcao_pessoa: 0,
              nome_funcao_pessoa: "",
              empregado: false,
              vendedor: false,
              secretaria: false,
              parceiro: false,
              id_plataforma: this.$route.params.id_plataforma,
              loading: false
            }
            this.alteraFastNavegacao('exibirFuncoes')
            this.exibeToasty("Função excluída com sucesso", "success")
            this.hideModal('modalExcluirFuncao')
            this.fastFuncaoPessoaEditar.loading = false     
          }).catch((e) => {
            this.exibeToasty("Erro ao excluir função", "error")
            this.fastFuncaoPessoaEditar.loading = false
          });
      }    
    },
  },
};
</script>

<style></style>
